<template>
  <b-card
    class="blog-edit-wrapper"
  >
    <!-- form -->
    <b-form
      class="uth-register-form mt-2"
      @submit.prevent="save"
    >
      <b-row>
        <!-- Titre -->
        <b-col md="6">
          <b-form-group
            label="Titre"
            label-for="blog-edit-title"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-title"
              v-model="faqCreate.titre"
            />
          </b-form-group>
        </b-col>
        <!-- Cible / Catégorie -->
        <!-- <b-col md="6">
          <b-form-group
            label="Cible / Catégorie"
            label-for="blog-edit-category"
            class="mb-2"
          >
            <v-select
              id="blog-edit-category"
              v-model="faqCreate.cibleCategorie"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :options="categoryOption"
            />
          </b-form-group>
        </b-col> -->
        <!-- Cible / Catégorie -->
        <b-col md="6">
          <b-form-group
            label="Cible / Catégorie"
            label-for="blog-edit-roles"
            class="mb-2"
          >
            <v-select
              v-model="faqCreate.roles"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="nom"
              :options="roles"
              :reduce="item=>item._id"
            />
          </b-form-group>
        </b-col>
        <!-- Contenu / Description -->
        <b-col cols="12">
          <b-form-group
            label="Contenu / Description"
            label-for="blog-content"
            class="mb-2"
          >
            <quill-editor
              id="blog-content"
              v-model="faqCreate.contenu"
              :options="snowOption"
            />
          </b-form-group>
        </b-col>
        <!-- Bannière -->
        <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Bannière
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <!-- <b-media-aside>
                <b-img
                  ref="refPreviewEl"
                  :src="faqCreate.banniere"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside> -->
              <b-media-body>
                <small class="text-muted">Résolution d'image requis 800x400, Taille de l'image 2mb.</small>
                <!-- <b-card-text class="my-50">
                  <b-link id="blog-image-text">
                    C:\fakepath\{{ faqFile ? faqFile.name : 'banner.jpg' }}
                  </b-link>
                </b-card-text> -->
                <div class="">
                  <b-form-file
                    ref="refInputEl"
                    v-model="faqCreate.banniere"
                    accept=".jpg, .png, .gif"
                    placeholder="Choisir un fichier"
                    @input="inputImageRenderer"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>
        <!-- Boutton d'enregistrement -->
        <b-col
          cols="12"
          class="mt-50 text-right"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
            :disabled="isLoading"
          >
            <b-spinner
              v-if="isLoading"
              small
            />
            <span v-if="!isLoading">
              Créer
            </span>

          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Fermer
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  // BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import localstorageService from '@/services/localstorage/localstorage.service'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { mapActions } from 'vuex'

// import du store
import faqStoreModule from '@/store/faq'
import paramsStoreModule from '@/store/params'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BCard,
    BMedia,
    // BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BSpinner,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  // created() {
  //   this.$http.get('/blog/list/data/edit').then(res => {
  //     this.faqCreate = res.data
  //   })
  // },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      refPreviewEl.value.src = base64
    })

    // import du store
    const requiredStoreModules = [
      { path: 'faq', module: faqStoreModule },
      { path: 'params', module: paramsStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      requiredStoreModules,
    }
  },
  data() {
    return {
      faqCreate: {
        titre: '',
        // cibleCategorie: '',
        // status: 'Publié',
        contenu: null,
        banniere: null,
        roles: [],
      },
      faqFile: null,
      categoryOption: ['Super Utilisateur', 'Agent du MTCA', 'Agent du gouvernement', "Promoteur d'établissement", "Agent d'établissement", 'Agent frontalier', 'Grand Public'],
      // statusOption: ['Publié', 'En attente', 'Brouillon'],
      snowOption: {
        theme: 'snow',
      },
      isLoading: false,
      roles: [],
    }
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  async mounted() {
    const userRoleReq = await this.action_fetchTypesRoles().catch(err => {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: err.message || 'Une erreur est survenue',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
    const { resources } = userRoleReq.data
    if (resources) this.roles = resources
    console.log('----------', this.roles)
  },

  methods: {
    ...mapActions('faq', {
      createFaq: 'createFaq',
    }),
    ...mapActions('params', { action_fetchTypesRoles: 'fetchTypesRoles' }),
    // ---------------------------------------------------
    async save() {
      if (this.isLoading) { return }

      this.isLoading = true

      const formData = new FormData()
      const objectdata = {
        titre: this.faqCreate.titre,
        // cibleCategorie: (this.faqCreate.cibleCategorie || []).filter(e => !!e),
        contenu: this.faqCreate.contenu,
        roles: this.faqCreate.roles.length > 0 ? this.faqCreate.roles.join() : [],
      }
      // console.log('objectdata :::: ', objectdata)
      formData.append('objectdata', JSON.stringify(objectdata))
      formData.append('fileprop', 'banniere')
      formData.append('filenumber', 1)
      const file = this.faqCreate.banniere
      formData.append('filedata0', file)

      this.createFaq(formData)
        .then(result => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistrement reussie',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          if (localstorageService.getUserId()) {
            this.$router.replace({ name: 'faq-list' })
          } else {
            this.$router.replace({ name: 'auth-login' })
          }
        })
        .catch(err => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
